<template>
    <div>
        <div
            class="page common-form teacher_box"
            v-if="type == 'teacher'"
        >
            <div class="common-form-layout">
                <global-page-back
                    detailPageTitle="档案分享"
                    @handlerGlobalType="back"
                >
                    <div
                        slot="globalPageBackTabs"
                        style="display: flex; align-items: center"
                    >
                        <el-button
                            type="primary"
                            @click="addEditGroup"
                            >添加编辑组</el-button
                        >
                    </div>
                </global-page-back>

                <div class="container">
                    <div class="common-form-wrapper content-bg main">
                        <div
                            v-if="type == 'student'"
                            class="link"
                        >
                            <div class="link-label">档案链接</div>
                            <div
                                class="link-url"
                                @click="handleUrlClick"
                            >
                                {{ url }}
                            </div>
                            <div class="link-copy">
                                <img
                                    :src="copyIcon"
                                    alt="复制"
                                />
                                <span
                                    @click="copy"
                                    class="copy"
                                    :data-clipboard-text="url"
                                    >复制</span
                                >
                            </div>
                        </div>
                        <div
                            class="common-form-main common-form-layout--scroll"
                        >
                            <div
                                class="common-form-view"
                                style="
                                    text-align: left;
                                    width: 100%;
                                    margin: none;
                                "
                            >
                                <div class="list content">
                                    <div
                                        class="item"
                                        v-for="(item, index) in list"
                                        :key="index"
                                    >
                                        <div class="box-top-line"></div>

                                        <el-form
                                            class="common-form"
                                            ref="form"
                                            :model="item"
                                            :rules="rules"
                                            label-position="right"
                                            label-width="130px"
                                        >
                                            <div
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                    align-items: center;
                                                "
                                            >
                                                <div class="group-main-title">
                                                    <div class="line"></div>
                                                    <div class="title">
                                                        {{
                                                            "权限组" +
                                                            (index + 1)
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="del-item"
                                                    v-if="index !== 0"
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                        @click="del(index)"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="group-each-box">
                                                <div class="group-box-left">
                                                    <div class="box-left-title">
                                                        权限范围
                                                    </div>
                                                    <div class="push_table">
                                                        <el-descriptions
                                                            :column="3"
                                                            border
                                                            v-for="(
                                                                items, indexs
                                                            ) in item.archivesTypesArr"
                                                            :key="indexs"
                                                        >
                                                            <el-descriptions-item
                                                                label-class-name="no-brackets"
                                                                content-class-name="no-my-content1"
                                                                v-if="
                                                                    items.firstlable ==
                                                                    '只读'
                                                                "
                                                                :span="3"
                                                            >
                                                                <span
                                                                    class="left_no_lable1"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '只读'
                                                                    "
                                                                    >权限名称</span
                                                                >
                                                                <span
                                                                    class="right_no_lable1"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '只读'
                                                                    "
                                                                >
                                                                    只读
                                                                </span>
                                                                <span
                                                                    class="right_no_lable1-right"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '只读'
                                                                    "
                                                                >
                                                                    管理
                                                                </span>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item
                                                                label-class-name="no-brackets"
                                                                content-class-name="no-my-content2"
                                                                v-if="
                                                                    items.firstlable ==
                                                                    '基础信息'
                                                                "
                                                                :span="3"
                                                            >
                                                                <span
                                                                    class="left_no_lable2"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '基础信息'
                                                                    "
                                                                    >基础信息</span
                                                                >
                                                                <span
                                                                    class="right_no_lable2"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '基础信息'
                                                                    "
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="0"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                                <span
                                                                    class="right_no_lable2-right"
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="1"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item
                                                                label-class-name="no-brackets"
                                                                content-class-name="no-my-content3"
                                                                v-if="
                                                                    items.firstlable ==
                                                                    '更多信息'
                                                                "
                                                                :span="3"
                                                            >
                                                                <span
                                                                    class="left_no_lable3"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '更多信息'
                                                                    "
                                                                    >更多信息</span
                                                                >
                                                                <span
                                                                    class="right_no_lable3"
                                                                    v-if="
                                                                        items.firstlable ==
                                                                        '更多信息'
                                                                    "
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="0"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                                <span
                                                                    class="right_no_lable3-right"
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="1"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item
                                                                v-else-if="
                                                                    items.firstlable !=
                                                                        '只读' &&
                                                                    items.firstlable !=
                                                                        '基础信息' &&
                                                                    items.firstlable !=
                                                                        '更多信息'
                                                                "
                                                                label-class-name="each-brackets"
                                                                content-class-name="each-my-content"
                                                                :label="
                                                                    items.archivesName
                                                                "
                                                            >
                                                                <span
                                                                    class="right_no_lable4"
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="0"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                                <span
                                                                    class="right_no_lable4-right"
                                                                >
                                                                    <el-radio
                                                                        v-model="
                                                                            items.editAble
                                                                        "
                                                                        label="1"
                                                                        @change="
                                                                            handlePushChecked(
                                                                                items,
                                                                                index,
                                                                            )
                                                                        "
                                                                        ><span></span
                                                                    ></el-radio>
                                                                </span>
                                                                <span
                                                                    class="boder_right_line"
                                                                ></span>
                                                            </el-descriptions-item>
                                                        </el-descriptions>

                                                        <div
                                                            slot="append"
                                                            class="more-box"
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div class="group-box-right">
                                                    <div
                                                        class="box-right-title"
                                                    >
                                                        <span
                                                            class="required-field"
                                                            >*</span
                                                        >{{
                                                            type == "teacher"
                                                                ? "部门"
                                                                : "班级"
                                                        }}
                                                    </div>
                                                    <el-form-item
                                                        class="class-select"
                                                        prop="stuList"
                                                        :rules="rules.stuList"
                                                    >
                                                        <div
                                                            class="tree-select-input"
                                                            @click="
                                                                openLoading(
                                                                    index,
                                                                    item,
                                                                )
                                                            "
                                                        >
                                                            <template
                                                                v-if="
                                                                    item.stuList
                                                                "
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        item2,
                                                                        index2
                                                                    ) in handlerSelectList(
                                                                        item.stuList,
                                                                    )"
                                                                    :key="
                                                                        index2
                                                                    "
                                                                    class="tree-select-input-tag"
                                                                >
                                                                    {{ item2 }}
                                                                </div>
                                                            </template>
                                                            <span
                                                                v-else
                                                                style="
                                                                    margin-left: 10px;
                                                                "
                                                                >请选择</span
                                                            >
                                                            <div
                                                                class="overlay"
                                                                v-if="overlayShow"
                                                            >
                                                                <img
                                                                    class="overlay_img"
                                                                    :src="loadingGif"
                                                                    alt="loading"
                                                                />
                                                            </div>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </el-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form-submit-btn>
                        <div slot="formSubmitBtn">
                            <el-button
                                @click="submit"
                                type="primary"
                                >保存</el-button
                            >
                        </div>
                    </form-submit-btn>

                    <img
                        class="bg"
                        :src="bgIcon"
                        alt=""
                    />
                </div>
            </div>

            <!--班级选择弹窗-->
            <DialogWrapper
                :dialog-obj="classDialogObj"
                @handleClose="closeClassTreeDialog"
            >
                <SelTree
                    :key="classDialogObj.key"
                    ref="classSelTree"
                    :selTreeOpt="classSelTreeOpt"
                    @setTreeData="classSetTreeData"
                    @cancel="closeClassTreeDialog"
                />
            </DialogWrapper>
        </div>
        <div
            class="student_box"
            v-if="type == 'student'"
        >
            <div class="page">
                <global-page-back
                    detailPageTitle="档案分享"
                    @handlerGlobalType="back"
                ></global-page-back>

                <div class="container">
                    <div class="main">
                        <div
                            v-if="type == 'student'"
                            class="link"
                        >
                            <div class="link-label">档案链接</div>
                            <div
                                class="link-url"
                                @click="handleUrlClick"
                            >
                                {{ url }}
                            </div>
                            <div class="link-copy">
                                <img
                                    :src="copyIcon"
                                    alt="复制"
                                />
                                <span
                                    @click="copy"
                                    class="copy"
                                    :data-clipboard-text="url"
                                    >复制</span
                                >
                            </div>
                        </div>

                        <div class="add-btn">
                            <el-button
                                type="primary"
                                @click="addEditGroup"
                                >添加编辑组</el-button
                            >
                        </div>

                        <div class="list">
                            <div
                                class="item"
                                v-for="(item, index) in list"
                                :key="index"
                            >
                                <div
                                    class="del-item"
                                    v-if="index !== 0"
                                >
                                    <i
                                        class="el-icon-delete"
                                        @click="del(index)"
                                    ></i>
                                </div>
                                <el-form
                                    class="common-form"
                                    ref="form"
                                    :model="item"
                                    :rules="rules"
                                    label-position="right"
                                    label-width="130px"
                                >
                                    <el-form-item
                                        class="class-select"
                                        :label="
                                            type == 'teacher' ? '部门' : '班级'
                                        "
                                        prop="stuList"
                                        :rules="rules.stuList"
                                    >
                                        <!-- <el-input
                                            class="input-width-small"
                                            v-model="item.stuList"
                                            placeholder="请选择"
                                            @focus="showClassTreeDialog(index, item)"
                                        ></el-input> -->
                                        <div
                                            class="input-width-small tree-select-input"
                                            @click="
                                                showClassTreeDialog(index, item)
                                            "
                                        >
                                            <template v-if="item.stuList">
                                                <div
                                                    v-for="(
                                                        item2, index2
                                                    ) in handlerSelectList(
                                                        item.stuList,
                                                    )"
                                                    :key="index2"
                                                    class="tree-select-input-tag"
                                                >
                                                    {{ item2 }}
                                                </div>
                                            </template>
                                            <span
                                                v-else
                                                style="margin-left: 10px"
                                                >请选择</span
                                            >
                                        </div>
                                    </el-form-item>
                                    <el-form-item
                                        :label="
                                            type == 'teacher'
                                                ? '档案权限'
                                                : '通过链接是否可编辑档案'
                                        "
                                        prop="editAble"
                                        :rules="rules.editAble"
                                    >
                                        <el-radio-group v-model="item.editAble">
                                            <el-radio label="1"
                                                >是（可编辑勾选的档案）</el-radio
                                            >
                                            <el-radio label="0"
                                                >否（可查看所有档案）</el-radio
                                            >
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item
                                        v-if="item.editAble == '1'"
                                        class="archives-types-arr"
                                        label="可编辑的档案"
                                        prop="archivesTypesArr"
                                        :rules="rules.archivesTypesArr"
                                    >
                                        <el-checkbox-group
                                            v-model="item.archivesTypesArr"
                                        >
                                            <el-checkbox
                                                v-for="subItem in conditionList"
                                                :label="subItem.archivesName"
                                                :key="subItem.id"
                                                :value="subItem.id"
                                            >
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>

                        <div
                            class="submit-btn"
                            align="center"
                        >
                            <xk-button
                                type="primary"
                                @click="submit"
                                >提交</xk-button
                            >
                        </div>
                    </div>

                    <img
                        class="bg"
                        :src="bgIcon"
                        alt=""
                    />
                </div>

                <!--班级选择弹窗-->
                <DialogWrapper
                    :dialog-obj="classDialogObj"
                    @handleClose="closeClassTreeDialog"
                >
                    <SelTree
                        :key="classDialogObj.key"
                        ref="classSelTree"
                        :selTreeOpt="classSelTreeOpt"
                        @setTreeData="classSetTreeData"
                        @cancel="closeClassTreeDialog"
                    />
                </DialogWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import {DialogWrapper, throttle, debounce} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import {treeFind, treeForeach} from "@/libs/utils.js";
import SelTree from "./Sub/SelTree2";
import {CampusSafetyModel} from "@/models/CampusSafety";
// 引入ajax models
import {CampusOAModel} from "@/models/CampusOA";

import Clipboard from "clipboard";
import FormSubmitBtn from "./Sub/FormSubmitBtn/index.vue";

export default {
    name: "",
    components: {
        DialogWrapper,
        GlobalPageBack,
        SelTree,
        FormSubmitBtn,
    },
    props: {},
    data() {
        return {
            schoolId: "",
            type: "teacher",
            url: "",
            clipboard: null,
            classOptions: [],
            /**
             * 获取树形数据
             * */
            treeData: {
                schoolId: "",
                applyPerson: "2", // 使用人群:1教职工,2学生,3全部
                peopleName: "",
            },
            // 可编辑档案
            conditionList: [],
            // 班级选择弹窗
            classDialogObj: {
                title: "选择",
                dialogVisible: false,
                width: "600px",
                key: 1,
            },
            // 指针,确定现在正在编辑的表单
            pointerNum: "",
            // 保存原始数据，添加编辑组
            addObjItem: {},
            // 班级选择树形选择器配置
            classSelTreeOpt: {
                key: "showName",
                data: [], // 树形数据
                showData: [], // 回显数据
                status: "multiple", // single单选 multiple
                maxLen: "", // 限制最多选择个数
            },
            list: [],
            rules: {
                stuList: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                editAble: [
                    {
                        required: true,
                        message: "请选择是否可编辑",
                        trigger: "change",
                    },
                ],
                archivesTypesArr: [
                    {
                        required: true,
                        message: "请选择可编辑的档案",
                        trigger: "change",
                    },
                ],
            },
            formData: {},
            listQuery: {},
            // --------------------
            setTableData: [],
            getTableData: [],
            addTableData: [],
            moreData: [], // 更多信息的各个权限
            selectDataMessage: [], // 选择后的数据
            getFormObj: {}, // 获取推送配置的data
            btnFormType: true,
            overlayShow: false, // 遮罩层
        };
    },
    filters: {},
    computed: {
        backIcon() {
            return require("@/assets/images/back2.png");
        },
        bgIcon() {
            return require("@/assets/images/stuInfoTemplate/bg.png");
        },
        copyIcon() {
            return require("@/assets/images/stuInfoTemplate/copy.png");
        },
        loadingGif () {
            return "https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/my/pullToRefresh.gif"
        },
    },
    async created() {
        this.$eventDispatch("setGlobalPageType", "form");
        console.log(this.$route.query.formData, this.$route.query.listQuery);
        this.formData = this.$route.query.formData; // 保存当前查询条件
        this.listQuery = this.$route.query.listQuery; // 保存当前查询条件
        await this.init();
        this.getClassTreeData();
    },
    methods: {
        /**
         * 初始化函数
         * assignment 赋值
         * getTreeData 后去班级数据
         * */
        init() {
            this.assignment();
            this.getConditionList();
            this.getTreeData();
        },
        assignment() {
            this.schoolId = this.$route.query.schoolId;
            this.type = this.$route.query.type
                ? this.$route.query.type
                : this.type;
            this.url =
                process.env.VUE_APP_STU_END_URL + "?schoolId=" + this.schoolId;

            this.treeData.schoolId = this.$route.query.schoolId;
            this.treeData.applyPerson = this.type == "teacher" ? 1 : 2; // 使用人群:1教职工,2学生,3全部

            this.rules.stuList[0].message =
                this.type == "teacher" ? "请选择部门" : "请选择班级";
            console.log("this.type_____________________", this.type);
        },
        getTreeData() {
            const campusOAModel = new CampusOAModel();
            campusOAModel.getConditionByList(this.treeData).then((res) => {
                if (res.data.code == "200") {
                    this.classSelTreeOpt.data = res.data.data;
                    console.log(
                        "[getTreeData]this.classSelTreeOpt.data",
                        this.classSelTreeOpt.data,
                    );
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        getConditionList() {
            console.log("teacher", this.type);

            const campusSafety = new CampusSafetyModel();
            campusSafety
                .conditionByList({
                    schoolId: this.$route.query.schoolId,
                    archivesPersonType: this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.conditionList = res.data.data;
                        console.log("[获取档案列表数据]", this.conditionList);
                        // 教师档案 数据处理
                        if (this.type == "teacher") {
                            let firstObj = {
                                archivesName: "权限名称",
                                id: "firsttitle",
                                firstlable: "只读",
                            };
                            let beforeObj = {
                                archivesName: "基础信息",
                                id: "34b6ee9902b54983807b9be416ab6ebe",
                                firstlable: "基础信息",
                                editAble: "0",
                                schoolId: this.schoolId,
                                archivesPersonType: "2",
                                updateType: "2",
                            };
                            let afterObj = {
                                archivesName: "更多信息",
                                id: "9f941adc1f3b4f72a46edf53c61a34a6",
                                editAble: "0",
                                schoolId: this.schoolId,
                                archivesPersonType: "2",
                                updateType: "2",
                                firstlable: "更多信息",
                            };
                            this.moreData = JSON.parse(
                                JSON.stringify(res.data.data),
                            );
                            res.data.data.unshift(
                                firstObj,
                                beforeObj,
                                afterObj,
                            );
                            // 其他信息
                            this.selectDataMessage = this.moreData;
                            this.setTableData = JSON.parse(
                                JSON.stringify(res.data.data),
                            );
                            this.getTableData = JSON.parse(
                                JSON.stringify(res.data.data),
                            );
                            this.addTableData = JSON.parse(
                                JSON.stringify(res.data.data),
                            );

                            // editAble 0 只读 1 管理
                            this.setTableData.forEach((iv) => {
                                iv.editAble = "0";
                            });
                            this.getTableData.forEach((iv) => {
                                iv.editAble = "0";
                            });
                        }
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        back() {
            // this.$router.go(-1)
            if (this.type == "teacher") {
                // this.$router.push('/summary/archives/summaryTeacher')
                this.$router.push({
                    name: "SummaryTeacher",
                    query: {
                        formData: this.formData, // 保存当前查询条件
                        listQuery: this.listQuery, // 保存当前查询条件
                    },
                });
            } else {
                // this.$router.push('/summary/archives/summaryStudent')
                this.$router.push({
                    name: "SummaryStudent",
                    query: {
                        formData: this.formData, // 保存当前查询条件
                        listQuery: this.listQuery, // 保存当前查询条件
                    },
                });
            }
            this.$eventDispatch("setGlobalPageType", "list");
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 获取学生信息
        getClassTreeData(data) {
            const campusSafety = new CampusSafetyModel();
            campusSafety
                .getIssueScopeByAccess({
                    schoolId: this.schoolId,
                    applyPerson: this.type == "teacher" ? 1 : 2, // 使用人群:1教职工,2学生,3全部
                })
                .then((res) => {
                    this.handleRes(res, () => {
                        this.classDialogObj.data = res.data.data;
                        treeForeach(
                            this.classDialogObj.data,
                            "children",
                            (node) => {
                                this.$set(
                                    node,
                                    "nodeId",
                                    node.parentOrg + node.id,
                                );
                            },
                        );

                        this.getList();
                    });
                });
        },
        getList() {
            this._fet("/school/schoolArchivesShareSetting/echoList", {
                schoolId: this.schoolId,
                archivesPersonType: this.type === "teacher" ? "2" : "1", // 1学生 2教师
            }).then((res) => {
                this.handleRes(res, () => {
                    console.log("[获取配置列表]接口返回：", res);
                    console.log("this.setTableData刚进来", this.setTableData);
                    // 教师档案 逻辑处理
                    if (this.type == "teacher") {
                        for (let item of res.data.data) {
                            item.archivesTypesArr = [];
                        }
                        res.data.data.forEach((item) => {
                            this.$set(item, "stuList", "");
                        });
                        if (res.data.data?.length > 0) {
                            this.addObjItem = this._.cloneDeep(
                                res.data.data[0],
                            );
                        } else {
                            this.addObjItem = {
                                archivesTypesArr: this.setTableData,
                                archivesTypesId: "",
                                deleteStatus: 0,
                                echoDetial: "",
                                editAble: "0",
                                id: "",
                                schoolId: this.schoolId,
                                stuList: "",
                                studentList: [],
                            };
                        }
                        console.log(
                            "[获取配置列表]this.addObjItem",
                            this.addObjItem,
                        );
                        this.list = res.data.data;
                        this.list.forEach((item) => {
                            if (item.archivesTypesId) {
                                let archivesList =
                                    item.archivesTypesId.split(",");
                                console.log("回显的", archivesList);
                                let hasArchiveList = [];
                                let getArr = JSON.parse(
                                    JSON.stringify(this.getTableData),
                                );
                                if (getArr && getArr.length > 0) {
                                    getArr.forEach((subItem) => {
                                        archivesList.forEach((it) => {
                                            if (it == subItem.id) {
                                                subItem.editAble = "1";
                                                hasArchiveList.push(subItem);
                                            }
                                        });
                                    });

                                    // 当全部选择的其他信息，勾选更多信息
                                    let normalList = this.setTableData.filter(
                                        (item) => !item.firstlable,
                                    );
                                    let hasArchiveAr = hasArchiveList.filter(
                                        (item) => !item.firstlable,
                                    );

                                    if (
                                        normalList.length > hasArchiveAr.length
                                    ) {
                                        getArr.forEach((item) => {
                                            if (
                                                item.archivesName == "更多信息"
                                            ) {
                                                item.editAble = "";
                                            }
                                        });
                                    }
                                }
                                item.archivesTypesArr = getArr;
                            } else {
                                if (item.editAble == "0") {
                                    this.addTableData.forEach((item) => {
                                        item.editAble = "0";
                                    });
                                    item.archivesTypesArr = JSON.parse(
                                        JSON.stringify(this.addTableData),
                                    );
                                }
                            }
                            console.log(
                                "item.archivesTypesArr",
                                item.archivesTypesArr,
                            );

                            item["stuList"] = "";
                            item["echoDetialList"] = item["studentList"];
                            item["echoDetialList"].forEach((item) => {
                                item.id = item.studentId;
                            });
                            item["studentList"].forEach((subItem) => {
                                // console.log('subItem', subItem)
                                if (subItem.studentName) {
                                    let name = " " + subItem.studentName;
                                    // console.log('name', name)
                                    item["stuList"] += name;
                                }
                            });
                        });
                    } else {
                        res.data.data.forEach((item) => {
                            this.$set(item, "stuList", "");
                        });
                        // this.addObjItem = JSON.parse(JSON.stringify(res.data.data[0]));
                        // console.log(this.addObjItem,'this.addObjItem')

                        if (res.data.data?.length > 0) {
                            this.addObjItem = JSON.parse(
                                JSON.stringify(res.data.data[0]),
                            );
                        } else {
                            this.addObjItem = {
                                archivesTypesArr: [],
                                archivesTypesId: "",
                                deleteStatus: 0,
                                echoDetial: "",
                                editAble: "1",
                                id: "",
                                schoolId: this.schoolId,
                                stuList: "",
                                studentList: [],
                            };
                        }
                        console.log(
                            "[获取配置列表]this.addObjItem",
                            this.addObjItem,
                        );
                        this.list = res.data.data;
                        this.list.forEach((item) => {
                            if (item.archivesTypesId) {
                                let archivesList =
                                    item.archivesTypesId.split(",");
                                item.archivesTypesArr = [];

                                this.conditionList.forEach((subItem) => {
                                    archivesList.forEach((it) => {
                                        if (it == subItem.id) {
                                            item.archivesTypesArr.push(
                                                subItem.archivesName,
                                            );
                                        }
                                    });
                                });
                            }

                            item["stuList"] = "";
                            item["echoDetialList"] = item["studentList"];
                            item["echoDetialList"].forEach((item) => {
                                item.id = item.studentId;
                            });
                            item["studentList"].forEach((subItem) => {
                                // console.log('subItem', subItem)
                                if (subItem.studentName) {
                                    let name = " " + subItem.studentName;
                                    // console.log('name', name)
                                    item["stuList"] += name;
                                }
                            });
                        });
                    }

                    this.list.sort(this.sortTime("createTime"));
                    console.log("[获取配置列表]this.list：", this.list);

                    if (this.list.length == 0) {
                        this.addEditGroup();
                    }
                });
            });
        },
        // 排序
        sortTime(time) {
            return function (m, n) {
                var a = m[time];
                var b = n[time];
                return a - b;
            };
        },
        // 点击链接
        handleUrlClick() {
            window.open(this.url, "_blank");
        },
        // 复制
        copy() {
            let _this = this;
            this.$nextTick(() => {
                if (_this.clipboard) {
                    _this.clipboard.destroy();
                }
                _this.clipboard = new Clipboard(".copy");
                //成功回调
                // console.log('[copy]clipboard', clipboard)
                _this.clipboard.on("success", function (e) {
                    // console.log("[copy][clipboard.on][success]e", e)
                    _this.$message.success("复制成功");
                    // e.clearSelection();
                });
                //失败回调
                _this.clipboard.on("error", function (e) {
                    _this.$message.error("复制失败，请手动复制链接。");
                });
            });
        },
        openLoading(idx, data) {
            this.overlayShow = true;
            setTimeout(() => {
                this.showClassTreeDialog(idx, data)
            },100)
        },
        // 显示选择班级/人员属性选择器
        showClassTreeDialog(idx, data) {
            this.pointerNum = idx;
            console.log(
                "[showClassTreeDialog]data.echoDetialList",
                data.echoDetialList,
            );
            if (data.echoDetialList && data.echoDetialList.length > 0) {
                let organIds = data.echoDetialList.map((i) => i.id);
                this.classSelTreeOpt.showData = [...organIds];
            } else {
                this.classSelTreeOpt.showData = [];
            }

            console.log(
                "[showClassTreeDialog]this.classSelTreeOpt.showData",
                this.classSelTreeOpt.showData,
            );
            this.classDialogObj.key += 1;
            this.classDialogObj.dialogVisible = true;
            this.overlayShow = false;
        },
        // 选中人员数据处理
        classSetTreeData(data) {
            // data.data  树选中节点
            // data.selList  右侧展示数据的name
            console.log("[选择班级]", data, "listttt");
            this.list.forEach((item, index) => {
                if (this.pointerNum == index) {
                    item.echoDetialList = data.data;
                    item.studentList = data.data;
                    console.log(item.echoDetialList, "item.echoDetialList");
                    item["stuList"] = "";
                    data.data.forEach((subItem) => {
                        if (subItem.name) {
                            let name = " " + subItem.name;
                            item["stuList"] += name;
                        }
                    });
                }
            });
            console.log(this.list, "=======", this.classSelTreeOpt);
            this.closeClassTreeDialog();
            //this.classDialogObj.dialogVisible = false;
        },
        // 关闭树形弹窗组件
        closeClassTreeDialog() {
            this.$refs.classSelTree.$data.filterText = "";
            this.classDialogObj.dialogVisible = false;
        },
        // 处理树形选择后显示在文本框的数据
        handlerSelectList(list) {
            // console.log("[handlerSelectList]list:", list)
            list = list.trim().split(" ");
            let length = list.length;
            // 教师档案 逻辑处理
            if (this.type == "teacher") {
                if (length > 30) {
                    list = list.slice(0, 29);
                    list.push(`+${length - 29}`);
                }
            } else {
                if (length > 7) {
                    list = list.slice(0, 6);
                    list.push(`+${length - 6}`);
                }
            }
            // console.log("[handlerSelectList]list:", list)
            if (list.length == 1 && list[0] == "") {
                return [];
            }
            return list;
        },
        submit() {
            let subType = this.$refs.form.map(async (item) => {
                await new Promise((reslove, reject) => {
                    item.validate((valid) => {
                        if (valid) {
                            reslove(valid);
                        } else {
                            reject(valid);
                        }
                    });
                });
            });

            Promise.all(subType)
                .then((res) => {
                    this.validationCompleteSubmit();
                })
                .catch((err) => {
                    console.log(err, "err");
                });
        },
        validationCompleteSubmit() {
            let obj = {
                    editAble: "",
                    stuList: [],
                    archivesTypesId: "",
                    echoDetialList: [],
                },
                arr = [],
                index = this.list.length;

            for (let i = 0; i < index; i++) {
                arr.push(JSON.parse(JSON.stringify(obj)));
            }
            // 教师档案 逻辑处理
            if (this.type == "teacher") {
                this.list.forEach((item, index) => {
                    arr.map((subItem, subIndex) => {
                        if (index == subIndex) {
                            subItem.stuList = [];
                            item.echoDetialList.forEach((it) => {
                                subItem.stuList.push(it.id);
                            });

                            subItem.editAble = item.editAble;

                            let archives = [];
                            item.archivesTypesArr.forEach((sub) => {
                                if (sub.editAble == "1") {
                                    archives.push(sub.id);
                                }
                            });

                            if (archives && archives.length == 0) {
                                subItem.archivesTypesId = "";
                            } else {
                                subItem.archivesTypesId = archives.join(",");
                                subItem.editAble = "1";
                            }
                        }
                    });
                });
            } else {
                this.list.forEach((item, index) => {
                    arr.map((subItem, subIndex) => {
                        if (index == subIndex) {
                            subItem.stuList = [];
                            item.echoDetialList.forEach((it) => {
                                subItem.stuList.push(it.id);
                            });

                            subItem.editAble = item.editAble;

                            let archives = [];
                            this.conditionList.forEach((it) => {
                                item.archivesTypesArr.forEach((sub) => {
                                    if (it.archivesName == sub) {
                                        archives.push(it.id);
                                    }
                                });
                            });

                            if (item.editAble == "0") {
                                subItem.archivesTypesId = "";
                            } else {
                                subItem.archivesTypesId = archives.join(",");
                            }
                        }
                    });
                });
            }

            // 校验是否有重复学生
            let allSelStuIds = arr.map((e) => e.stuList).flat(Infinity);
            let allSelStuIdsDeDup = [...new Set(allSelStuIds)];
            let allSelStuIdsDup = allSelStuIdsDeDup.filter(
                (e) => allSelStuIds.filter((i) => i == e).length > 1,
            );
            if (allSelStuIdsDup.length > 0) {
                let dupStuNames = [];
                for (let id of allSelStuIdsDup) {
                    let name = treeFind(
                        this.classSelTreeOpt.data,
                        (e) => e.id == id,
                    )?.name;
                    if (name) {
                        dupStuNames.push(name);
                    }
                }
                this.$message.error("有重复学生：" + dupStuNames.join("、"));
                return;
            }

            let reqObj = {
                schoolId: this.schoolId,
                archivesPersonType: this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
                list: arr,
            };
            console.log("reqObj", reqObj);

            const campusSafety = new CampusSafetyModel();
            campusSafety.conditionSave(reqObj).then((res) => {
                if (res.data.code == "200") {
                    this.$message.success("提交成功");
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },

        /**
         * *编辑组相关
         * addEditGroup 添加编辑组
         * del 删除编辑组
         * */
        addEditGroup() {
            // 教师档案 逻辑处理
            if (this.type == "teacher") {
                console.log("this.addTableData", this.addTableData);
                this.addTableData.forEach((item) => {
                    item.editAble = "0";
                });
                this.addObjItem = {
                    archivesTypesArr: JSON.parse(
                        JSON.stringify(this.addTableData),
                    ),
                    archivesTypesId: "",
                    deleteStatus: 0,
                    echoDetial: "",
                    editAble: "0",
                    id: "",
                    schoolId: this.schoolId,
                    stuList: "",
                    studentList: [],
                };
                this.list.push(this.addObjItem);
                console.log("this.list", this.list);
            } else {
                this.list.push(JSON.parse(JSON.stringify(this.addObjItem)));
            }
        },
        del(idx) {
            this.list.forEach((item, index) => {
                if (idx == index) {
                    this.list.splice(index, 1);
                }
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 更多信息全选
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushAll(val, index) {
            console.log("全选的value", val, index);
            console.log(
                "this.list[index]?.archivesTypesArr",
                this.list[index]?.archivesTypesArr,
            );

            this.selectDataMessage = [];
            this.list[index]?.archivesTypesArr.forEach((item) => {
                if (!item.firstlable) {
                    item.editAble = val;
                    this.selectDataMessage.push(item);
                }
            });
        },
        /**
         * @Description: 更多的单选
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushChecked(rows, index) {
            console.log("rows~~~~~~~~~~~~", rows, index);
            console.log("this.list_______________________", this.list[index]);

            // editAble 0 只读 1 管理
            // 判断是‘基础信息’还是‘更多信息’
            if (rows.archivesName == "基础信息") {
                // 基础信息
                console.log("基础信息的勾选", rows.editAble);
            } else if (rows.archivesName == "更多信息") {
                // 更多信息
                this.handlePushAll(rows.editAble, index);
                console.log("全选后选中的值", this.selectDataMessage);
            } else {
                // 选择其他信息权限，更多信息的选择去掉
                this.list[index]?.archivesTypesArr.forEach((item) => {
                    if (item.archivesName == "更多信息") {
                        item.editAble = "";
                    }
                });
                // 当全部选择的其他信息，勾选更多信息
                let normalList = this.list[index]?.archivesTypesArr.filter(
                    (item) => !item.firstlable,
                );

                let readOnlyList = normalList.filter((item0) => {
                    return item0.editAble == "0";
                });
                let editList = normalList.filter((item1) => {
                    return item1.editAble == "1";
                });
                if (readOnlyList.length == normalList.length) {
                    this.list[index]?.archivesTypesArr.forEach((item) => {
                        if (item.archivesName == "更多信息") {
                            item.editAble = "0";
                        }
                    });
                }
                if (editList.length == normalList.length) {
                    this.list[index]?.archivesTypesArr.forEach((item) => {
                        if (item.archivesName == "更多信息") {
                            item.editAble = "1";
                        }
                    });
                }
            }
            this.$forceUpdate();
        },
    },
};
</script>
<style lang="scss" scoped>
.student_box {
    $pageHeight: calc(100vh - 120px);
    $pagePadding: 16px;
    .container {
        margin: $pagePadding $pagePadding $pagePadding 0;
        box-sizing: border-box;
        background: #fff;
        height: calc(#{$pageHeight} - #{$pagePadding} * 2);
        position: relative;
        overflow: hidden;
    }

    .back {
        position: absolute;
        top: 24px;
        left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        z-index: 2;

        img {
            width: 8px;
            height: 12px;
            object-fit: contain;
        }

        span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            margin-left: 5px;
        }
    }

    .bg {
        width: 1582px;
        height: 729px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .main {
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 40px 0;
        width: 880px;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        height: $pageHeight;
        box-sizing: border-box;
    }

    .link {
        height: 56px;
        background: #e8f2fc;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;

        .link-label {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #595959;
        }

        .link-url {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3c7fff;
            margin-left: 26px;
            cursor: pointer;
        }

        .link-copy {
            margin-left: auto;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 14px;
                height: 14px;
            }

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #3c7fff;
                line-height: 48px;
                margin-left: 8px;
            }
        }
    }

    .add-btn {
        margin-top: 32px;
    }

    .list {
        margin-top: 24px;
        flex: 1;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 10px;
    }
    .item {
        background: #ffffff;
        border: 1px solid #e6e8eb;
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 32px 0 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        ::v-deep .el-form {
            width: 80%;

            .el-form-item {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-form-item__content {
                    margin-left: 0 !important;
                    flex: 1;
                }
            }
        }

        .del-item {
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            i {
                color: #3c7fff;
                cursor: pointer;
            }
        }
    }
    .class-select {
        width: 100%;

        ::v-deep .el-select {
            width: 100%;
        }

        ::v-deep .el-select__tags {
            max-width: unset !important;
        }

        .tree-select-input {
            border: 1px solid #dcdee0;
            height: 32px;
            -webkit-appearance: none;
            border-radius: 4px;
            box-sizing: border-box;
            color: gray;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            display: flex;
            flex-direction: row;
            align-items: center;

            .tree-select-input-tag {
                height: 24px;
                background-color: #f4f4f5;
                border-radius: 4px;
                border: 1px solid #e9e9eb;
                box-sizing: border-box;
                margin: 2px 0 2px 6px;
                line-height: 22px;
                text-align: center;
                padding: 0 8px;
                font-size: 12px;
                color: #909399;
                white-space: nowrap;
                width: 57px;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-shrink: 0;

                + .tree-select-input-tag {
                    margin-left: 6px;
                }
            }
        }
    }
    ::v-deep .el-checkbox.is-checked {
        .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #3c7efe;
            border-color: #3c7efe;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #3c7efe;
        }
    }

    ::v-deep .archives-types-arr {
        .el-form-item__content {
            line-height: 1;
        }

        .el-checkbox__label {
            line-height: 1;
        }
    }

    ::v-deep .el-form-item__label {
        line-height: 20px;
    }
    ::v-deep .global-page-back .tab-list {
        justify-content: flex-end !important;
    }
}
.teacher_box {
    .container {
        .content {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            overflow: auto;
        }
    }

    .back {
        position: absolute;
        top: 24px;
        left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        z-index: 2;

        img {
            width: 8px;
            height: 12px;
            object-fit: contain;
        }

        span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            margin-left: 5px;
        }
    }

    .bg {
        width: 1582px;
        height: 729px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .main {
        z-index: 1;
    }

    .link {
        height: 56px;
        background: #e8f2fc;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;

        .link-label {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #595959;
        }

        .link-url {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3c7fff;
            margin-left: 26px;
            cursor: pointer;
        }

        .link-copy {
            margin-left: auto;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 14px;
                height: 14px;
            }

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #3c7fff;
                line-height: 48px;
                margin-left: 8px;
            }
        }
    }

    .list {
        background-color: #fff;
        height: calc(100vh - 198px);
    }
    .item {
        .del-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 20px;
            i {
                color: #3c7fff;
                cursor: pointer;
            }
        }
    }
    .class-select {
        width: 100%;

        ::v-deep .el-form-item__content {
            margin-left: 0 !important;
        }

        ::v-deep .el-select__tags {
            max-width: unset !important;
        }

        .tree-select-input {
            border: 1px solid #dcdee0;
            width: 325px;
            flex-wrap: wrap;
            word-break: break-all; /* 允许在单词内换行 */
            max-height: 212px;
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-appearance: none;
            border-radius: 4px;
            box-sizing: border-box;
            color: gray;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .tree-select-input-tag {
                height: 24px;
                background-color: #f4f4f5;
                border-radius: 4px;
                border: 1px solid #e9e9eb;
                box-sizing: border-box;
                margin: 2px 0 2px 6px;
                line-height: 22px;
                text-align: center;
                padding: 0 8px;
                font-size: 12px;
                color: #909399;
                white-space: nowrap;
                width: 57px;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-shrink: 0;

                + .tree-select-input-tag {
                    margin-left: 6px;
                }
            }
        }
        
        .overlay {
            position: absolute;
            top: 14px;
            left: 0;
            z-index: 2001;
            width: 100%;
            height: calc(100% - 42px);
            background-color: rgba(255, 255, 255, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .overlay_img {
            width: 20px;
        }
    }

    ::v-deep .el-checkbox.is-checked {
        .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #3c7efe;
            border-color: #3c7efe;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #3c7efe;
        }
    }

    ::v-deep .archives-types-arr {
        .el-form-item__content {
            line-height: 1;
        }

        .el-checkbox__label {
            line-height: 1;
        }
    }

    ::v-deep .el-form-item__label {
        line-height: 20px;
    }
    .common-form-wrapper {
        white-space: normal;
        margin-top: 0px;
        padding-top: 10px;
    }
    ::v-deep .common-form-main {
        height: calc(100vh - 198px);
        border-radius: 4px 0px 0px 4px;
    }
    ::v-deep
        .common-form-layout
        .common-form-wrapper
        .common-form-main
        .common-form-view {
        margin: 0 auto 0 0;
    }
    .page {
    }
    .item {
        margin-bottom: 10px;
        .box-top-line {
            border-top: 1px solid #d4d4d4;
            margin-bottom: 10px;
            margin-top: 16px;
        }
        &:first-child > .box-top-line {
            border-top: none;
        }
    }
    .group-main-title {
        height: 30px;
        display: flex;
        align-items: center;
        justify-self: flex-start;
        .line {
            width: 3px;
            height: 14px;
            background: #3c7fff;
            border-radius: 2px;
            margin-left: 23px;
            margin-right: 6px;
        }
        .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2b2f33;
        }
    }
    .group-each-box {
        display: flex;
        .group-box-left {
            width: 582px;
            margin-left: 20px;
            .box-left-title {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 36px;
            }
        }
        .group-box-right {
            margin-left: 15px;
            .required-field {
                color: #ff0000;
                margin-right: 5px;
            }
            .box-right-title {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 36px;
            }
        }
    }
    .push_table {
        max-height: 420px;
        overflow-y: auto;
    }
    .more-box {
        border-top: 1px solid #ebeef5;
    }
    .more-check {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #3c7fff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
    }
    .el-icon-arrow-down {
        transition: 0.2s;
        line-height: 32px;
        color: #2e8ae6;
    }
    .arrowTransform {
        transform: rotateZ(180deg);
    }

    .left_no_lable1 {
        display: inline-block;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #2b2f33;
        // width: 288px;
        width: 49.1%;
        text-align: center;
    }
    .left_no_lable2 {
        display: inline-block;
        background: #ffffff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        // width: 288px;
        width: 49.1%;
        text-align: center;
    }
    .left_no_lable3 {
        display: inline-block;
        background: #f9fbff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        // width: 288px;
        width: 49.1%;
        text-align: center;
    }
    .right_no_lable1 {
        display: inline-block;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        // width: 132px;
        width: 24%;
    }
    .right_no_lable1-right {
        display: inline-block;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        // width: 138px;
        width: 24%;
        color: #2b2f33;
        text-align: center;
        margin-left: 7px;
    }

    .right_no_lable2 {
        display: inline-block;
        background: #ffffff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        // width: 140px;
        width: 25%;
    }
    .right_no_lable2-right {
        display: inline-block;
        background: #ffffff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        // width: 138px;
        width: 25.5%;
        text-align: center;
        margin-left: 2px;
    }
    .right_no_lable3 {
        display: inline-block;
        background: #f9fbff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        // width: 140px;
        width: 25%;
    }
    .right_no_lable3-right {
        // width: 138px;
        width: 25.5%;
        display: inline-block;
        background: #f9fbff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        margin-left: 2px;
    }
    .right_no_lable4 {
        display: inline-block;
        background: #f9fbff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        // width: 120px;
        width: 44%;
    }
    .right_no_lable4-right {
        // width: 138px;
        width: 51%;
        display: inline-block;
        background: #f9fbff;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
        text-align: center;
        margin-left: 12px;
    }
    .boder_right_line {
        width: 0.2px;
        background-color: #e1e3e6;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
::v-deep .global-page-back .tab-list {
    justify-content: flex-end !important;
}
::v-deep {
    .common-form-layout .common-form-wrapper .common-form-main {
        padding: 0 !important;
    }
    .el-descriptions .is-bordered .el-descriptions-item__cell {
        border: 1px solid #e1e3e6;
        border-bottom: none !important;
    }
    .el-descriptions--small.is-bordered .el-descriptions-item__cell {
        padding: 8px 0 8px 10px;
    }
    .no-brackets {
        display: none !important; /* 隐藏标签 */
    }
    .no-my-content1 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #fafafa !important;
        border-radius: 4px 4px 0px 0px;
    }
    .no-my-content2 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #ffffff !important;
    }
    .no-my-content3 {
        width: 100%;
        margin-left: 0 !important; /* 移除左边距 */
        background: #f9fbff !important;
    }
    .each-brackets {
        display: inline-block;
        // width: 299.2px !important;
        width: 50% !important;
        text-align: center !important;
        background: #f9fbff;
        border-right: none !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px !important;
        color: #2b2f33 !important;
        white-space: nowrap;
        /* 确保文本不换行 */
        overflow: hidden;
        /* 超出容器部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分用省略号表示 */
        user-select: none;
    }
    .each-my-content {
        display: inline-block;
        text-align: center;
        background: #f9fbff !important;
        position: relative;
        width: 50%;
    }
    .el-descriptions-row {
        display: flex;
        width: 582.2px !important;
    }
    .el-loading-spinner {
        margin-top: -10px;
    }
    .el-loading-spinner .circular {
        width: 21px;
        height: 21px;
    }
}
::v-deep .bg {
    z-index: -1 !important;
}
::v-deep .common-form-layout .common-form-btn {
    position: relative;
    z-index: 10;
}
::v-deep .global-page-back .tab-list {
    justify-content: flex-end !important;
}
</style>
